import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App backgroundoverlay">
        <p className="App-intro">
          Welcome to the home of Burnside Hot Sauces!

          Please reach out with questions and inquiries to contact [at] burnsidehotsauce [dot] com
        </p>
      </div>
    );
  }
}

export default App;
